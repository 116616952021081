<template>
  <div id="basicinfoshow">
    <pageHeader />

    <div class="basicInfo">
      <v-container fluid class="container-fluid-padding">
        <v-row>
          <v-col>
            <div class="breadcrumb mt-4">
              <v-breadcrumbs :items="items">
                <template v-slot:divider>
                  <v-icon large color="#a53860">mdi-chevron-left</v-icon>
                </template>
              </v-breadcrumbs>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <div class="d-flex d-md-none justify-space-between">
              <v-btn
                icon
                color="#a53860"
                @click="toggleNavMenu"
                title="فتح قائمة التصفح"
              >
                <v-icon large>mdi-menu-open</v-icon>
              </v-btn>
              <v-btn
                :to="`/salon/${allSalonInfo.id}`"
                title="صفحة الصالون الرئيسية"
                color="#a53860"
                class="white--text"
                >صفحة الصالون الرئيسية
              </v-btn>
            </div>
            <salonSettingsDrawer ref="navMenu" class="d-block d-md-none">
              <div class="sideMenu sideMenu-drawer mt-4">
                <ul class="unstyled info-sidemenu">
                  <li @click="chageTab(1)" :class="tab == 1 ? 'active' : ''">
                    البيانات الاساسية للصالون
                  </li>
                  <hr class="main-hr" />
                  <li @click="chageTab(2)" :class="tab == 2 ? 'active' : ''">
                    الفروع
                  </li>
                  <hr class="main-hr" />
                  <li @click="chageTab(3)" :class="tab == 3 ? 'active' : ''">
                    ساعات العمل
                  </li>
                  <hr class="main-hr" />
                  <li @click="chageTab(4)" :class="tab == 4 ? 'active' : ''">
                    قنوات التواصل الإجتماعي
                  </li>
                  <hr class="main-hr" />
                  <li @click="chageTab(5)" :class="tab == 5 ? 'active' : ''">
                    بيانات تسجيل الدخول
                  </li>
                  <hr class="main-hr" />
                  <li @click="chageTab(6)" :class="tab == 6 ? 'active' : ''">
                    معلومات الدفع
                  </li>
                  <hr class="main-hr" />
                </ul>
              </div>
            </salonSettingsDrawer>
            <div class="sideMenu mt-4 d-none d-md-block">
              <ul class="unstyled info-sidemenu">
                <li @click="chageTab(1)" :class="tab == 1 ? 'active' : ''">
                  البيانات الاساسية للصالون
                </li>
                <hr class="main-hr" />
                <li @click="chageTab(2)" :class="tab == 2 ? 'active' : ''">
                  الفروع
                </li>
                <hr class="main-hr" />
                <li @click="chageTab(3)" :class="tab == 3 ? 'active' : ''">
                  ساعات العمل
                </li>
                <hr class="main-hr" />
                <li @click="chageTab(4)" :class="tab == 4 ? 'active' : ''">
                  قنوات التواصل الإجتماعي
                </li>
                <hr class="main-hr" />
                <li @click="chageTab(5)" :class="tab == 5 ? 'active' : ''">
                  بيانات تسجيل الدخول
                </li>
                <hr class="main-hr" />
                <li @click="chageTab(6)" :class="tab == 6 ? 'active' : ''">
                  معلومات الدفع
                </li>
                <hr class="main-hr" />
              </ul>
            </div>
          </v-col>
          <v-col sm="12" md="9" class="pa-0">
            <basicInfoShow :allSalonInfo="allSalonInfo" v-if="tab == 1">
              <v-btn
                :to="`/salon/${allSalonInfo.id}`"
                title="صفحة الصالون الرئيسية"
                color="#a53860"
                class="white--text d-none d-md-inline-block"
                >صفحة الصالون الرئيسية
              </v-btn>
            </basicInfoShow>
            <branchesShow
              :branches="allSalonInfo.branches"
              @updateBranch="updateBranch"
              @deleteBranch="deleteBranch"
              @addNewBranch="addNewBranch"
              v-if="tab == 2"
            >
              <v-btn
                :to="`/salon/${allSalonInfo.id}`"
                title="صفحة الصالون الرئيسية"
                color="#a53860"
                class="white--text d-none d-md-inline-block"
                >صفحة الصالون الرئيسية
              </v-btn>
            </branchesShow>

            <workingHours v-if="tab == 3">
              <v-btn
                :to="`/salon/${allSalonInfo.id}`"
                title="صفحة الصالون الرئيسية"
                color="#a53860"
                class="white--text d-none d-md-inline-block"
                >صفحة الصالون الرئيسية
              </v-btn>
            </workingHours>
            <salonSocialMedia v-if="tab == 4">
              <v-btn
                :to="`/salon/${allSalonInfo.id}`"
                title="صفحة الصالون الرئيسية"
                color="#a53860"
                class="white--text d-none d-md-inline-block"
                >صفحة الصالون الرئيسية
              </v-btn>
            </salonSocialMedia>
            <loginCredentials v-if="tab == 5">
              <v-btn
                :to="`/salon/${allSalonInfo.id}`"
                title="صفحة الصالون الرئيسية"
                color="#a53860"
                class="white--text d-none d-md-inline-block"
                >صفحة الصالون الرئيسية
              </v-btn>
            </loginCredentials>
            <paymentInfo v-if="tab == 6">
              <v-btn
                :to="`/salon/${allSalonInfo.id}`"
                title="صفحة الصالون الرئيسية"
                color="#a53860"
                class="white--text d-none d-md-inline-block"
                >صفحة الصالون الرئيسية
              </v-btn>
            </paymentInfo>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pageHeader from "@/components/pageHeader.vue";
import basicInfoShow from "@/components/salon/basicInfoShow.vue";
import branchesShow from "@/components/salon/branchesShow.vue";
import workingHours from "@/components/salon/workingHours.vue";
import salonSocialMedia from "@/components/salon/salonSocialMedia.vue";
import loginCredentials from "@/components/salon/loginCredentials.vue";
import paymentInfo from "@/components/salon/paymentInfo.vue";
import salonSettingsDrawer from "@/components/salon/salonSettingsDrawer.vue";

export default {
  name: "salonSettings",
  components: {
    pageHeader,
    basicInfoShow,
    branchesShow,
    workingHours,
    salonSocialMedia,
    loginCredentials,
    paymentInfo,
    salonSettingsDrawer,
  },
  data() {
    return {
      show1: false,
      show2: false,
      tab: "",
      allSalonInfo: {},
      items: [
        {
          text: "الرئيسية",
          disabled: false,
          href: "/",
        },
        {
          text: "الاعدادات",
          disabled: true,
          href: "breadcrumbs_link_1",
        },
      ],
      cities: [],
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    toggleNavMenu() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      this.$refs.navMenu.toggleDrawer();
    },
    deleteBranch(branchIndex) {
      this.allSalonInfo.branches.splice(branchIndex, 1);
    },
    updateBranch(branchObj, branchIndex, countries, cities) {
      let cityName = cities.find((city) => city.id == branchObj.city_id).name;
      branchObj.city = cityName;
      branchObj.countryName = countries.find(
        (country) => country.id == branchObj.country
      ).name;
      branchObj.formatted_address = this.makeFormattedAddress(branchObj);
      this.allSalonInfo.branches[branchIndex] = branchObj;
    },
    makeFormattedAddress(branchObj) {
      return (
        branchObj.street +
        " " +
        branchObj.district +
        " " +
        branchObj.city +
        " " +
        branchObj.countryName
      );
    },
    addNewBranch(branchObj, countries, cities) {
      let cityName = cities.find((city) => city.id == branchObj.city_id).name;

      branchObj.city = cityName;
      branchObj.countryName = countries.find(
        (country) => country.id == branchObj.country
      ).name;
      branchObj.formatted_address = this.makeFormattedAddress(branchObj);
      this.allSalonInfo.branches.push(branchObj);
    },
    chageTab(tabIndex) {
      this.tab = tabIndex;
      this.$router.push("?tab=" + this.tab);
    },
    getSalonInfo() {
      this.$http
        .get(this.base_url + "/front/getSalonBasiceInfoWeb", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.allSalonInfo = response.data.data;
          }
        });
    },
  },
  mounted() {
    this.getSalonInfo();
    this.tab = this.$route.query.tab;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";
#basicinfoshow {
  .basicInfo {
    margin-bottom: 50px !important;
  }
  .info-sidemenu {
    li {
      cursor: pointer;
      font-size: 1.5rem !important;
      color: $main-color;
    }
    .main-hr {
      margin: 1rem 0;
      width: 80%;
    }
    li.active {
      color: #b7b7b7;
    }
  }
}
#basicinfoshow {
  .sideMenu-drawer {
    .info-sidemenu {
      li {
        font-size: 1rem !important;
      }
      .main-hr {
        width: 100% !important;
      }
    }
  }
}

@media (max-width: 600px) {
  .container-fluid-padding {
    padding: 12px !important;
  }
  #basicinfoshow {
    .info-sidemenu {
      li {
        font-size: 1rem !important;
      }
    }
  }
}
</style>
