<template>
  <div>
    <!-- ======================= delete dialog ==================== -->
    <v-dialog v-model="dialog" width="30%">
      <v-card id="contact-dialog">
        <v-btn icon @click="closeConfirmDialog">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
        <v-card-text class="text-center pa-4 pa-sm-4">
          <p class="second-color message-send">{{ text }}</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="#a53860" class="white--text" @click.prevent="confirm()"
            >نعم</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "confirmDialog",
  props: ["dialog", "text", "confirmType"],
  data() {
    return {};
  },
  methods: {
    confirm() {
      this.$emit("confirm", this.confirmType);
    },
    closeConfirmDialog() {
      this.$emit("closeConfirmDialog");
    },
  },
  mounted() {},
};
</script>

<style></style>
