<template>
  <div id="salonSocialMedia">
    <div class="head">
      <v-row class="align-baseline">
        <v-col cols="12" sm="8" class="pb-0 pb-sm-3">
          <h2 class="main-title mb-0 mb-sm-3">
            قنوات التواصل الإجتماعي
          </h2></v-col
        >
        <v-col cols="12" sm="4" class="text-sm-left">
          <slot></slot>
        </v-col>
      </v-row>
      <p class="d-flex align-center">
        <v-icon color="#a53860" small>mdi-checkbox-blank-circle</v-icon>
        <span class="line"></span>
      </p>
    </div>
    <div class="socialChannels mr-sm-12" v-if="edit == false">
      <v-row>
        <v-col cols="12" sm="8">
          <p
            class="subscribe-msg"
            v-if="
              socialMediaObj.can_show_social_media == false &&
              socialMediaObj.message
            "
          >
            <v-icon large color="#a53860">mdi-alert-circle-outline</v-icon>
            هذه الخدمة متاحة عند الاشتراك. لمعرفة خطط الاشتراك لدينا

            <a :href="socialMediaObj.pricing_link"> انقر هنا</a>
          </p>
          <div
            v-if="
              loaded == true &&
              socialMediaObj.can_show_social_media == true &&
              !socialMediaObj.facebook_link &&
              !socialMediaObj.twitter_link &&
              !socialMediaObj.instagram_link &&
              !socialMediaObj.snapchat_link
            "
          >
            <p class="text-center error--text">لا يوجد قنوات تواصل اجتماعى</p>
          </div>
          <div v-else-if="socialMediaObj.can_show_social_media == true">
            <div class="socialItem" v-if="socialMediaObj.facebook_link">
              <a
                :href="socialMediaObj.facebook_link"
                target="_blank"
                class="main-link d-block"
              >
                <span class="icon d-inline-block ml-3"
                  ><i class="fab fa-facebook-f"></i
                ></span>
                <span class="text d-inline-block">فيس بوك</span>
              </a>
              <p class="item-link mr-6">{{ socialMediaObj.facebook_link }}</p>
            </div>

            <div class="socialItem" v-if="socialMediaObj.twitter_link">
              <a
                :href="socialMediaObj.twitter_link"
                target="_blank"
                class="main-link d-block"
              >
                <span class="icon d-inline-block ml-3"
                  ><i class="fab fa-twitter"></i
                ></span>
                <span class="text d-inline-block">تويتر</span>
              </a>
              <p class="item-link mr-6">{{ socialMediaObj.twitter_link }}</p>
            </div>
            <div class="socialItem" v-if="socialMediaObj.instagram_link">
              <a
                :href="socialMediaObj.instagram_link"
                target="_blank"
                class="main-link d-block"
              >
                <span class="icon d-inline-block ml-3"
                  ><i class="fab fa-instagram"></i
                ></span>
                <span class="text d-inline-block">انستجرام</span>
              </a>
              <p class="item-link mr-6">{{ socialMediaObj.instagram_link }}</p>
            </div>
            <div class="socialItem" v-if="socialMediaObj.snapchat_link">
              <a
                :href="socialMediaObj.snapchat_link"
                target="_blank"
                class="main-link d-block"
              >
                <span class="icon d-inline-block ml-3"
                  ><i class="fab fa-snapchat-ghost"></i
                ></span>
                <span class="text d-inline-block">سناب شات</span>
              </a>
              <p class="item-link mr-6">{{ socialMediaObj.snapchat_link }}</p>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="text-center"
          v-if="socialMediaObj.can_show_social_media == true"
        >
          <v-btn class="main-btn" @click="openEditMode()">تعديل</v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-else class="socialChannels mr-sm-12">
      <v-form v-model="valid" ref="form">
        <v-row>
          <v-col cols="12" sm="8">
            <p
              class="text-center error--text"
              v-if="validationMsg"
              v-html="validationMsg"
            ></p>
            <div class="socialItem">
              <label for="facebook" target="_blank" class="main-link d-block">
                <span class="icon d-inline-block ml-3"
                  ><i class="fab fa-facebook-f"></i
                ></span>
                <span class="text d-inline-block">فيس بوك</span>
              </label>
              <v-text-field
                class="mr-6"
                v-model="socialEditObj.facebook_link"
              />
            </div>
            <div class="socialItem">
              <label for="facebook" target="_blank" class="main-link d-block">
                <span class="icon d-inline-block ml-3"
                  ><i class="fab fa-twitter"></i
                ></span>
                <span class="text d-inline-block">تويتر</span>
              </label>
              <v-text-field class="mr-6" v-model="socialEditObj.twitter_link" />
            </div>
            <div class="socialItem">
              <label for="facebook" target="_blank" class="main-link d-block">
                <span class="icon d-inline-block ml-3"
                  ><i class="fab fa-instagram"></i
                ></span>
                <span class="text d-inline-block"> انستجرام</span>
              </label>
              <v-text-field
                class="mr-6"
                v-model="socialEditObj.instagram_link"
              />
            </div>
            <div class="socialItem">
              <label for="facebook" target="_blank" class="main-link d-block">
                <span class="icon d-inline-block ml-3"
                  ><i class="fab fa-snapchat-ghost"></i
                ></span>
                <span class="text d-inline-block">سناب شات</span>
              </label>
              <v-text-field
                class="mr-6"
                v-model="socialEditObj.snapchat_link"
              />
            </div>
          </v-col>
          <v-col cols="12" sm="4" class="text-center">
            <v-btn class="main-btn ml-3" @click="saveSocialMedia">حفظ</v-btn>
            <v-btn class="cancel-btn" @click.prevent="edit = false"
              >الغاء</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "salonSocialMedia",
  data() {
    return {
      valid: false,
      edit: false,
      loaded: false,
      validationMsg: "",
      socialMediaObj: {},
      socialEditObj: {
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
        snapchat_link: "",
      },
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    openEditMode() {
      this.socialEditObj.facebook_link = this.socialMediaObj.facebook_link;
      this.socialEditObj.twitter_link = this.socialMediaObj.twitter_link;
      this.socialEditObj.instagram_link = this.socialMediaObj.instagram_link;
      this.socialEditObj.snapchat_link = this.socialMediaObj.snapchat_link;
      this.edit = true;
    },
    saveSocialMedia() {
      this.$http
        .post(
          this.base_url + "/salon/socialMediaForSalon",
          this.socialEditObj,
          {
            headers: {
              locale: localStorage.locale,
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.socialMediaObj.facebook_link =
              this.socialEditObj.facebook_link;
            this.socialMediaObj.twitter_link = this.socialEditObj.twitter_link;
            this.socialMediaObj.instagram_link =
              this.socialEditObj.instagram_link;
            this.socialMediaObj.snapchat_link =
              this.socialEditObj.snapchat_link;
            this.edit = false;
          } else {
            this.validationMsg = response.data.status.message;
          }
        });
    },
    getAllSocialMedia() {
      this.loaded = false;
      this.$http
        .get(this.base_url + "/salon/socialMediaForSalon", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.loaded = true;
          console.log(response.data);
          this.socialMediaObj = response.data.data;
        });
    },
  },
  mounted() {
    this.getAllSocialMedia();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";
.main-btn,
.cancel-btn {
  width: 100px;
}
#salonSocialMedia {
  .head {
    .main-title {
      color: $second-color;
      font-size: 2rem;
    }
    .line {
      display: inline-block;
      width: 100%;
      height: 1px;
      background: $second-color;
      margin-right: -3px !important;
    }
  }
  .subscribe-msg {
    color: $second-color !important;
  }

  .socialItem {
    .item-link {
      word-break: break-all;
    }
    .main-link {
      font-size: 1.2rem;
      color: $second-color;
      text-decoration: unset !important;

      .icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $second-color;
        color: #ffffff;
        text-align: center;
        svg {
          margin-top: 5px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  #salonSocialMedia {
    .head {
      .main-title {
        font-size: 1.5rem;
      }
    }
  }
}
</style>
