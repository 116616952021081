<template>
  <div id="toTimeInput">
    <v-menu
      ref="menu"
      v-model="menu2"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="time"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-form v-model="valid" ref="form">
          <div class="toField d-flex align-baseline">
            <span class="px-4 d-inline-block">الى</span>
            <v-text-field
              v-model="time"
              append-icon="mdi-clock-time-four-outline"
              :rules="[
                startTimeRule(
                  fromValue,
                  toValue,
                  ' لا يمكن اختيار الوقت الذي يساوي أو أقل من وقت البدا.'
                ),
              ]"
              readonly
              v-bind="attrs"
              v-on="on"
              class="mt-0 pt-0"
              outlined
              dense
            ></v-text-field>
          </div>
        </v-form>
      </template>
      <v-time-picker
        color="#a53860"
        v-if="menu2"
        v-model="time"
        full-width
        @click:minute="$refs.menu.save(time)"
      ></v-time-picker>
    </v-menu>
  </div>
</template>

<script>
import { validationMixin } from "@/mixins/validationMixin";

export default {
  name: "toTimeInput",
  props: ["index", "toValue", "fromValue"],
  mixins: [validationMixin],

  data() {
    return {
      valid: false,
      time: null,
      menu2: false,
    };
  },
  watch: {
    time: {
      handler() {
        this.$emit("updateToValue", this.index, this.time);
      },
    },
  },
  methods: {
    settoValue() {
      console.log("asd");
    },
  },
  mounted() {
    console.log(this.index);
    this.time = this.toValue;
  },
};
</script>

<style></style>
