<template>
  <div id="loginCredentials">
    <div class="head">
      <v-row class="align-baseline">
        <v-col cols="12" sm="8" class="pb-0 pb-sm-3">
          <h2 class="main-title mb-0 mb-sm-3">بيانات تسجيل الدخول</h2></v-col
        >
        <v-col cols="12" sm="4" class="text-sm-left">
          <slot></slot>
        </v-col>
      </v-row>
      <p class="d-flex align-center">
        <v-icon color="#a53860" small>mdi-checkbox-blank-circle</v-icon>
        <span class="line"></span>
      </p>
    </div>

    <div class="logindataShow mr-sm-12 mt-12">
      <v-row v-if="editMobile == false && editPassword == false">
        <v-col cols="12" sm="12">
          <div class="datashow">
            <div class="mobile mb-8" v-if="salonMobileNumber">
              <span class="d-inline-block text font-bold main-color">
                رقم الجوال</span
              >
              <span class="d-inline-block value"
                >+966 {{ salonMobileNumber }}</span
              >
              <span class="d-inline-block icon pr-5">
                <v-btn @click.prevent="openMobileEditMode()" icon>
                  <v-icon color="#a53860">mdi-pencil</v-icon>
                </v-btn>
              </span>
            </div>
            <div class="password">
              <span class="d-inline-block text font-bold main-color">
                كلمة المرور</span
              >
              <span class="d-inline-block value">*********</span>
              <span class="d-inline-block icon pr-5">
                <v-btn @click.prevent="openPasswordEditMode" icon>
                  <v-icon color="#a53860">mdi-pencil</v-icon>
                </v-btn>
              </span>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="editMobile == true">
        <v-col cols="12" md="9">
          <p class="text-center error--text" v-if="validationMsg">
            {{ validationMsg }}
          </p>
          <v-form v-model="valid" ref="form">
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <label for="mobile" class="main-color">رقم الجوال</label>
              </v-col>
              <v-col cols="12" sm="9" md="7">
                <v-row class="align-baseline">
                  <v-col cols="6" sm="8" class="pr-0">
                    <v-text-field
                      placeholder="رقم الجوال"
                      v-model="mobileInfo.mobile"
                      :rules="[
                        validationRules.required,
                        validationRules.numeric,
                        validationRules.spaces,
                      ]"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" sm="4">
                    <v-autocomplete
                      :aria-autocomplete="false"
                      v-model="mobileInfo.country_code_id"
                      label="كود البلد"
                      class="ltr-direction"
                      :items="Codes"
                      required
                      @change="hideLabel = true"
                      :rules="[validationRules.required]"
                      item-text="title"
                      item-value="value"
                      outlined
                      dense
                      disabled
                    >
                      <!-- v-model="editedItem.country_code_id" -->
                      <template slot="selection" slot-scope="data">
                        <!-- <img src="../../assets/flags/AF.png" />
                    {{ data.title }}-->

                        <v-flex xs3>
                          <v-avatar size="25">
                            <img :src="data.item.icon" />
                          </v-avatar>
                        </v-flex>
                        <v-flex class="ml-3">{{ data.item.title }}</v-flex>
                      </template>
                      <template slot="item" slot-scope="data">
                        <v-list-item-avatar
                          style="width: 20px; min-width: 20px; height: 20px"
                        >
                          <img :src="data.item.icon" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.title"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="align-baseline">
              <v-col cols="12" sm="3">
                <label for="mobile" class="main-color"> كلمة المرور</label>
              </v-col>
              <v-col cols="12" sm="9" md="7">
                <v-text-field
                  v-model="mobileInfo.password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  name="password"
                  @click:append="show1 = !show1"
                  :rules="[validationRules.required, validationRules.spaces]"
                  autocomplete="off"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row> </v-form
        ></v-col>
        <v-col cols="12" md="3" class="text-center">
          <v-btn class="main-btn ml-3" @click.prevent="saveMobileData"
            >حفظ</v-btn
          >
          <v-btn class="cancel-btn" @click.prevent="editMobile = false"
            >الغاء</v-btn
          >
        </v-col>
      </v-row>

      <v-row v-if="editPassword == true">
        <v-col cols="12" md="9">
          <p class="text-center error--text" v-if="validationMsg">
            {{ validationMsg }}
          </p>
          <v-form v-model="valid" ref="form">
            <v-row class="align-baseline">
              <v-col cols="4" md="3">
                <label for="mobile" class="main-color">
                  كلمة المرور الحالية</label
                >
              </v-col>
              <v-col cols="8" md="7">
                <v-text-field
                  v-model="passwordInfo.password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  name="password"
                  @click:append="show1 = !show1"
                  :autocomplete="false"
                  :rules="[
                    validationRules.required,
                    validationRules.spaces,
                    validationRules.password,
                  ]"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="align-baseline">
              <v-col cols="4" md="3">
                <label for="mobile" class="main-color">
                  كلمة المرور الجديدة</label
                >
              </v-col>
              <v-col cols="8" md="7">
                <v-text-field
                  v-model="passwordInfo.new_password"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"
                  name="password"
                  autocomplete="false"
                  :rules="[
                    validationRules.required,
                    validationRules.spaces,
                    validationRules.password,
                  ]"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col cols="12" md="3" class="text-center">
          <v-btn class="main-btn ml-3" @click.prevent="savePasswordData"
            >حفظ</v-btn
          >
          <v-btn class="cancel-btn" @click.prevent="editPassword = false"
            >الغاء</v-btn
          >
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { validationMixin } from "@/mixins/validationMixin";

export default {
  name: "loginCredentials",
  mixins: [validationMixin],
  data() {
    return {
      valid: false,
      show1: false,
      show2: false,
      editMobile: false,
      editPassword: false,
      salonMobileNumber: localStorage.salonMobileNumber,
      validationMsg: "",
      Codes: [],
      mobileInfo: {
        mobile: "",
        country_code_id: 187,
        password: "",
      },
      passwordInfo: {
        password: "",
        new_password: "",
      },
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    openPasswordEditMode() {
      this.editPassword = true;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
    openMobileEditMode() {
      this.validationMsg = "";
      this.editMobile = true;
      this.mobileInfo.mobile = this.salonMobileNumber;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
    saveMobileData() {
      this.validationMsg = "";
      if (this.valid == true) {
        if (this.mobileInfo.mobile != this.salonMobileNumber) {
          this.$http
            .post(
              this.base_url + "/salon/changesaloneMobile",
              this.mobileInfo,
              {
                headers: {
                  locale: localStorage.locale,
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == false) {
                localStorage.removeItem("token");
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("type");
                localStorage.removeItem("email");
                localStorage.removeItem("phone");
                window.location.href = "/login";
              } else {
                this.validationMsg = response.data.status.message;
              }
            });
        } else {
          this.validationMsg = " رقم الجوال الحالي والجديد متطابقتان";
        }
      } else {
        this.validate();
      }
    },
    savePasswordData() {
      this.validationMsg = "";
      if (this.valid) {
        if (this.passwordInfo.password != this.passwordInfo.new_password) {
          this.$http
            .post(
              this.base_url + "/salon/changesalonePassword",
              {
                password: this.passwordInfo.password,
                "new-password": this.passwordInfo.new_password,
              },
              {
                headers: {
                  locale: localStorage.locale,
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == false) {
                localStorage.removeItem("token");
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("type");
                localStorage.removeItem("email");
                localStorage.removeItem("phone");
                window.location.href = "/login";
              } else {
                this.validationMsg = response.data.status.message;
              }
            });
        } else {
          this.validationMsg = "كلمة المرور الحالية والجديدة متطابقتان";
        }
      } else {
        this.validate();
      }
    },
    getCountryCodes() {
      this.$http.get(this.base_url + "/front/getCodes").then((response) => {
        this.Codes = response.data.data;
      });
    },
  },
  mounted() {
    this.getCountryCodes();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";
.main-btn,
.cancel-btn {
  width: 100px !important;
}
#loginCredentials {
  .head {
    .main-title {
      color: $second-color;
      font-size: 2rem;
    }
    .line {
      display: inline-block;
      width: 100%;
      height: 1px;
      background: $second-color;
      margin-right: -3px !important;
    }
  }

  .logindataShow {
    font-size: 1.2rem !important;
    .mobile {
      .text {
        width: 150px;
        max-width: 25% !important;
      }
      .value {
        width: 200px;
        direction: ltr !important;
        max-width: 50% !important;
      }
      .icon {
        max-width: 25% !important;
      }
    }
    .password {
      .text {
        width: 200px;
        max-width: 25% !important;
      }
      .value {
        width: 150px;
        max-width: 50% !important;
      }
      .icon {
        max-width: 25% !important;
      }
    }
  }
}

@media (max-width: 600px) {
  #loginCredentials {
    .head {
      .main-title {
        font-size: 1.5rem;
      }
    }
  }
}
</style>
