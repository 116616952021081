<template>
  <div id="paymentInfo">
    <div class="head">
      <v-row class="align-baseline">
        <v-col cols="12" sm="8" class="pb-0 pb-sm-3">
          <h2 class="main-title mb-0 mb-sm-3">معلومات الدفع</h2></v-col
        >
        <v-col cols="12" sm="4" class="text-sm-left">
          <slot></slot>
        </v-col>
      </v-row>
      <p class="d-flex align-center">
        <v-icon color="#a53860" small>mdi-checkbox-blank-circle</v-icon>
        <span class="line"></span>
      </p>
    </div>
    <div class="paymentInfo">
      <v-row v-if="!edit && paymentData && paymentData.card_holder_name">
        <v-col cols="12" sm="4">
          <div class="paymentNetwork">
            <h2 class="second-color">شبكة الدفع</h2>
            <img
              src="../../assets/visa.jpg"
              width="60px"
              alt="visa"
              class="payment-method"
              v-if="paymentData && paymentData.payment_method == 'visa'"
            />
            <img
              style="height: 56px"
              src="../../assets/mastercard.png"
              width="60px"
              alt="mastercard"
              class="payment-method"
              v-if="paymentData && paymentData.payment_method == 'mastercard'"
            />
            <img
              style="height: 56px"
              src="../../assets/Mada_Logo.svg"
              width="60px"
              alt="mada"
              class="payment-method"
              v-if="paymentData && paymentData.payment_method == 'mada'"
            />
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <h2 class="second-color">بيانات الكارت</h2>
          <div class="cardInfo">
            <p>{{ paymentData.card_holder_name }}</p>
            <p>{{ paymentData.card_number }}</p>
            <p>{{ paymentData.exp_month }} / {{ paymentData.exp_year }}</p>
            <p>{{ paymentData.cvc }}</p>
          </div>
        </v-col>
        <v-col cols="12" sm="4" class="text-center">
          <v-btn class="main-btn ml-3" @click.prevent="openNewEditMode('edit')"
            >تعديل</v-btn
          >
        </v-col>
      </v-row>

      <v-row v-if="edit">
        <v-col cols="12" sm="6" md="4">
          <h2 class="mr-4">وسائل الدفع</h2>
          <v-radio-group
            row
            v-model="paymentData.payment_method"
            class="payment-methods"
          >
            <v-radio label="visa" value="visa">
              <template v-slot:label>
                <img
                  src="../../assets/visa.jpg"
                  width="60px"
                  alt="visa"
                  class="payment-method"
                  :class="paymentData.payment_method == 'visa' ? 'active' : ''"
                />
              </template>
            </v-radio>
            <v-radio label="masterCard" value="mastercard">
              <template v-slot:label>
                <img
                  style="height: 56px"
                  src="../../assets/mastercard.png"
                  width="60px"
                  alt="visa"
                  class="payment-method"
                  :class="
                    paymentData.payment_method == 'mastercard' ? 'active' : ''
                  "
                />
              </template>
            </v-radio>
            <v-radio label="mada" value="mada">
              <template v-slot:label>
                <img
                  style="height: 56px"
                  src="../../assets/Mada_Logo.svg"
                  width="60px"
                  alt="mada"
                  class="payment-method"
                  :class="paymentData.payment_method == 'mada' ? 'active' : ''"
                />
              </template>
            </v-radio>
          </v-radio-group>
          <p class="error--text" v-if="paymentTypeMsg">
            {{ paymentTypeMsg }}
          </p>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <h2>معلومات الدفع</h2>

          <v-form
            v-model="valid"
            ref="form"
            class="mt-4"
            id="payment_form"
            action="https://api.moyasar.com/v1/payments.html"
            method="POST"
          >
            <v-row>
              <v-col cols="9" class="pa-0">
                <v-text-field
                  label="إسم حامل البطاقة ثلاثي"
                  name="source[name]"
                  v-model="paymentData.card_holder_name"
                  :rules="[
                    validationRules.required,
                    checkCardName(paymentData.card_holder_name),
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="9" class="pa-0">
                <v-text-field
                  label="رقم البطاقة"
                  name="source[number]"
                  v-model="paymentData.card_number"
                  :rules="[
                    validationRules.required,
                    validationRules.numeric,
                    validationRules.maxLength16,
                    onlinePaymentValidation(paymentData.card_number),
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="9" class="pa-0">
                <v-row>
                  <v-col
                    ><v-select
                      :items="months"
                      name="source[month]"
                      label="شهر الإنتهاء"
                      v-model="paymentData.exp_month"
                      :rules="[
                        validationRules.required,
                        checkDate(paymentData.exp_year, paymentData.exp_month),
                      ]"
                    ></v-select
                  ></v-col>
                  <v-col
                    ><v-select
                      label="سنة الإنتهاء"
                      name="source[year]"
                      :items="years"
                      v-model="paymentData.exp_year"
                      :rules="[
                        validationRules.required,
                        checkDate(paymentData.exp_year, paymentData.exp_month),
                      ]"
                    ></v-select
                  ></v-col>
                </v-row>
              </v-col>
              <v-col cols="9" class="pa-0">
                <v-text-field
                  label="رمز التحقق من البطاقة"
                  name="source[cvc]"
                  v-model="paymentData.cvc"
                  :rules="[
                    validationRules.required,
                    validationRules.numeric,
                    validationRules.maxLength3,
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col cols="12" sm="" md="4" class="text-center">
          <v-btn class="main-btn ml-3" @click.prevent="savePaymentData()"
            >حفظ</v-btn
          >
          <v-btn class="cancel-btn" @click.prevent="cancelPayment()"
            >الغاء</v-btn
          >
        </v-col>
      </v-row>
    </div>

    <v-row v-if="!paymentData.card_holder_name && loaded && !edit">
      <v-col cols="12" sm="8">
        <p>لايوجد معلومات دفع</p>
      </v-col>
      <v-col cols="12" sm="4" class="text-center">
        <v-btn class="main-btn ml-3" @click.prevent="openNewEditMode('new')"
          >اضافة
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "@/mixins/validationMixin";

export default {
  name: "paymentInfo",
  mixins: [validationMixin],

  data() {
    return {
      valid: false,
      loaded: false,
      paymentTypeMsg: "",

      newData: false,
      edit: false,
      paymentData: {
        payment_method: "",
        card_holder_name: "",
        card_number: "",
        exp_month: "",
        paymentData: "",
        cvc: "",
      },
      originalpaymentData: {
        payment_method: "",
        card_holder_name: "",
        card_number: "",
        exp_month: "",
        paymentData: "",
        cvc: "",
      },
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      years: [],
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  watch: {
    "paymentData.payment_method": {
      handler() {
        this.paymentTypeMsg = "";
      },
    },
  },
  methods: {
    savePaymentData() {
      if (this.valid && this.paymentData.payment_method) {
        if (this.newData) {
          // add new Payment info
          this.$http
            .post(
              this.base_url + "/salon/addPaymentInformation",
              this.paymentData,
              {
                headers: {
                  locale: localStorage.locale,
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((res) => {
              if (res.data.status.error == false) {
                this.edit = false;
                this.newData = false;
                this.paymentTypeMsg = "";
              }
            });
        } else {
          // update Payment info
          this.$http
            .post(
              this.base_url + "/front/updatePaymentInformation",
              this.paymentData,
              {
                headers: {
                  locale: localStorage.locale,
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((res) => {
              if (res.data.status.error == false) {
                console.log(res.data);
                this.edit = false;
              }
            });
        }
      } else {
        this.validate();
        this.paymentTypeMsg = !this.paymentData.payment_method
          ? "هذا الحقل مطلوب"
          : "";
      }
    },
    cancelPayment() {
      this.paymentData = { ...this.originalpaymentData };
      this.edit = false;
      this.newData = false;
    },
    generateYears() {
      const currentYear = new Date().getFullYear();
      for (let i = currentYear; i <= currentYear + 8; i++) {
        this.years.push(i.toString());
      }
    },
    openNewEditMode(type = "") {
      this.edit = true;
      this.paymentTypeMsg = "";
      if (type == "new") {
        this.newData = true;
      }
    },

    checkCardName(cardName) {
      if (!new RegExp("\\w+\\s\\w+\\s\\w+.*").test(cardName)) {
        return "يجب ان يكون الاسم ثلاثى";
      } else {
        return true;
      }
    },
    getUserPaymentInfo() {
      this.$http
        .get(this.base_url + "/front/getPaymentInformation/?uuid=", {
          headers: {
            locale: localStorage.locale,
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.loaded = true;
          if (
            response.data.data.card_holder_name &&
            response.data.data.card_number &&
            response.data.data.cvc
          ) {
            this.paymentData = response.data.data;
            this.originalpaymentData = { ...response.data.data };
          }
        });
    },
  },
  mounted() {
    this.getUserPaymentInfo();
    this.generateYears();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";
.main-btn,
.cancel-btn {
  width: 100px !important;
}
#paymentInfo {
  .head {
    .main-title {
      color: $second-color;
      font-size: 2rem;
    }
    .line {
      display: inline-block;
      width: 100%;
      height: 1px;
      background: $second-color;
      margin-right: -3px !important;
    }
  }

  .paymentNetwork {
    .payment-method {
      margin: 2rem 12px;
    }
  }
  .cardInfo {
    margin: 2rem 0;
  }
}

@media (max-width: 600px) {
  #paymentInfo {
    .head {
      .main-title {
        font-size: 1.5rem;
      }
    }
  }
}

@media (max-width: 960px) and (min-width: 600px) {
  .payment-method {
    width: 50px !important;
  }
}
</style>
