<template>
  <div class="basicInfo">
    <div class="head">
      <v-row class="align-baseline">
        <v-col cols="12" sm="8" class="pb-0 pb-sm-3">
          <h2 class="main-title mb-0 mb-sm-3">
            البيانات الاساسية للصالون
          </h2></v-col
        >
        <v-col cols="12" sm="4" class="text-sm-left">
          <slot></slot>
        </v-col>
      </v-row>
      <p class="d-flex align-center">
        <v-icon color="#a53860" small>mdi-checkbox-blank-circle</v-icon>
        <span class="line"></span>
      </p>
    </div>

    <v-row>
      <v-col cols="12" sm="8">
        <div class="info-content">
          <div>
            <h3 class="main-color font-bold sec-title">البيانات الاساسية</h3>
            <hr class="info-hr" />
            <p class="info-title second-color mb-0">اسم الصالون</p>
            <p class="value mb-2">{{ allSalonInfo.name }}</p>
            <p class="info-title second-color mb-0">الرقم الضريبى</p>
            <p class="value mb-2">{{ allSalonInfo.tax_number }}</p>

            <p class="info-title second-color mb-0">رسوم الخدمة بالمنزل</p>
            <p class="value mb-2">
              {{ allSalonInfo.home_service_fees }}
            </p>
          </div>
          <div class="mt-4">
            <h3 class="main-color font-bold sec-title">الصالون</h3>
            <hr class="info-hr" />
            <div
              class="service"
              v-for="(service, index) in allSalonInfo.services"
              :key="index"
            >
              <p class="info-title second-color mb-0">{{ service.name }}</p>
              <div class="sessions mb-2">
                <span
                  class="value ml-8"
                  v-for="(session, i) in service.sessions"
                  :key="i"
                >
                  {{ session.name }}
                </span>
              </div>
            </div>
          </div>
          <div class="edit-btn">
            <v-btn
              class="edit white--text d-none d-sm-flex"
              to="/saloninfo"
              large
              color="#a53860"
              >تعديل</v-btn
            >
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <div class="salon-images">
          <h3 class="main-color font-bold">صور الصالون</h3>
          <div class="logo-div">
            <!-- ============== logo =================== -->
            <div v-if="allSalonInfo.logo_show && allSalonInfo.logo_show.url">
              <img
                :src="allSalonInfo.logo_show.url"
                id="logo"
                alt=""
                width="100px"
              />
            </div>

            <p class="second-color">صورة اللوجو</p>
          </div>
          <div class="basicimg-div">
            <!-- ============== basic image =================== -->

            <div
              v-if="
                allSalonInfo.main_image_show && allSalonInfo.main_image_show.url
              "
            >
              <img
                :src="allSalonInfo.main_image_show.url"
                id="basic"
                alt=""
                width="100px"
              />
            </div>

            <p class="second-color">الصورة الاساسية</p>
          </div>

          <div class="other-div">
            <div class="other-images">
              <!-- ============== other images =================== -->
              <div
                v-for="(img, index) in allSalonInfo.other_images_show"
                :key="index"
                class="other-image"
              >
                <img id="otehr_image" :src="img.url" alt="" width="100px" />
              </div>
            </div>

            <p
              class="second-color"
              v-if="
                allSalonInfo.other_images_show &&
                allSalonInfo.other_images_show.length > 0
              "
            >
              صور أخرى
            </p>
          </div>
        </div>

        <div class="edit-btn text-center mt-4">
          <v-btn
            class="edit main-btn d-inline-block d-sm-none"
            to="/saloninfo"
            color="#a53860"
            >تعديل</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "basicInfoShow",

  props: ["allSalonInfo"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";
.basicInfo {
  .head {
    .main-title {
      color: $second-color;
      font-size: 2rem;
    }
    .line {
      display: inline-block;
      width: 100%;
      height: 1px;
      background: $second-color;
      margin-right: -3px !important;
    }
  }
  .info-content {
    position: relative;
    .sec-title {
      font-size: 1.5rem;
    }
    .info-title {
      font-size: 1.2rem !important;
    }
    .info-hr {
      border-top: 0 !important;
      margin: 10px 0;
      max-width: 60%;
      border-bottom: 2px solid $main-color;
    }

    .edit-btn .v-btn {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 150px !important;
    }
  }
  .salon-images {
    background-color: #f7f7f7;
    padding: 20px;
    text-align: center;
    border-radius: 0 0 0 60px;

    .logo-div {
      margin-top: 25px;
      position: relative;

      img {
        width: 65px;
        height: 65px;
        border: 1px solid $second-color;
        border-radius: 50%;
      }
      .cam-icon {
        font-size: 60px !important;
      }
      .edit {
        position: absolute;
        bottom: 12px;
        right: 25%;
      }
    }

    .basicimg-div {
      position: relative;
      margin-top: 25px;
      img {
        width: 65px;
        height: 65px;
        border: 1px solid $second-color;
        border-radius: 50%;
      }
      .cam-icon {
        font-size: 60px !important;
      }
      .edit {
        position: absolute;
        bottom: 12px;
        right: 25%;
      }
    }

    .other-div {
      // margin-top: 25px;
      .other-images {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        .other-image {
          position: relative;
          margin-top: 30px;

          .delete-btn {
            position: absolute;
            right: 10px;
            bottom: -19px;
          }
          img {
            width: 40px;
            height: 40px;
            margin: 0 3px;
            border: 1px solid $second-color;
            border-radius: 50%;
          }
        }
        .cam-icon {
          margin: 0 3px;
          font-size: 40px;
          margin: 0 3px;
          margin-top: 30px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .basicInfo {
    .head {
      .main-title {
        font-size: 1.5rem;
      }
    }
  }
}
</style>
