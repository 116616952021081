<template>
  <div id="branches" class="info-content">
    <div class="head">
      <v-row class="align-baseline">
        <v-col cols="12" sm="8" class="pb-0 pb-sm-3">
          <h2 class="main-title mb-0 mb-sm-3">الفروع</h2>
        </v-col>
        <v-col cols="12" sm="4" class="text-sm-left">
          <slot></slot>
        </v-col>
      </v-row>
      <p class="d-flex align-center">
        <v-icon color="#a53860" small>mdi-checkbox-blank-circle</v-icon>
        <span class="line"></span>
      </p>
    </div>

    <div class="address-addEdit mr-sm-12">
      <v-row v-if="!edit">
        <v-col cols="12" sm="9">
          <div
            class="branche-item d-flex align-baseline"
            v-for="(branch, index) in branches"
            :class="branch.active == false ? 'unactive-branch' : ''"
            :key="index"
          >
            <v-tooltip bottom v-if="branch.active == true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="pt-1"
                  @click="copyText(branch.url)"
                >
                  <v-icon>mdi-link</v-icon>
                </v-btn>
              </template>
              <span>{{ text }}</span>
            </v-tooltip>
            <v-btn v-else icon class="pt-1" :disabled="true">
              <v-icon>mdi-link</v-icon>
            </v-btn>
            <p class="mb-0">
              <strong :class="branch.active == false ? '' : 'second-color'">{{
                branch.name
              }}</strong>
              {{ branch.formatted_address }}
            </p>

            <v-btn
              icon
              class="mx-1"
              :to="`/branch?id=${branch.id}`"
              :disabled="branch.active == false"
            >
              <v-icon :color="branch.active == false ? '#c1c1c1' : '#a53860'"
                >mdi-map-marker</v-icon
              >
            </v-btn>
            <v-btn class="mx-1" icon @click="editBranch(index)"
              ><v-icon color="#a53860">mdi-pencil</v-icon></v-btn
            >

            <v-btn
              icon
              class="mx-1"
              @click="openConfirmDialog(branch.id, index)"
              v-if="branch.can_delete == true"
              ><v-icon color="#a53860">mdi-close-circle-outline</v-icon></v-btn
            >
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="mx-1"
                  ><v-icon color="#CBCBCB"
                    >mdi-close-circle-outline</v-icon
                  ></v-btn
                >
              </template>
              <span
                >لا يمكنك حذف هذا الفرع لأنه تم استخدامه في الحجوزات
                السابقة</span
              >
            </v-tooltip>
          </div>
        </v-col>
        <v-col cols="12" sm="3" class="text-center">
          <v-btn class="main-btn" @click="addNewBranch">اضافة</v-btn>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" md="8" class="pa-0 mt-12">
          <p class="error--text text-center" v-if="validationMsg">
            {{ validationMsg }}
          </p>
          <v-form class="basicInfoForm" v-model="valid" ref="form">
            <v-row>
              <v-col cols="12" class="py-0">
                <label for="branchName" class="main-color">إسم الفرع</label>
                <v-text-field
                  name="branchName"
                  v-model="branchObj.name"
                  :rules="[validationRules.required, validationRules.spaces]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="5" class="py-0">
                <label for="country" class="main-color">البلد</label>
                <v-select
                  class=""
                  :rules="[validationRules.required, validationRules.spaces]"
                  name="country"
                  :items="countries"
                  item-text="name"
                  item-value="id"
                  v-model="branchObj.country"
                  :disabled="true"
                />
              </v-col>
              <v-col cols="2" class="d-none d-sm-block"></v-col>
              <v-col cols="12" sm="5" class="py-0">
                <label for="city" class="main-color">المدينة</label>
                <v-select
                  class=""
                  name="city"
                  :items="cities"
                  item-text="name"
                  item-value="id"
                  v-model="branchObj.city_id"
                  :rules="[validationRules.required, validationRules.spaces]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="5" class="py-0">
                <label for="neighborhood" class="main-color">إسم الحي</label>
                <v-text-field
                  class=""
                  name="neighborhood"
                  v-model="branchObj.district"
                  :rules="[validationRules.required, validationRules.spaces]"
                />
              </v-col>
              <v-col cols="2" class="d-none d-sm-block"></v-col>
              <v-col cols="12" sm="5" class="py-0">
                <label for="street" class="main-color">إسم الشارع</label>
                <v-text-field
                  class=""
                  name="street"
                  v-model="branchObj.street"
                  :rules="[validationRules.required, validationRules.spaces]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="5" class="py-0">
                <label for="zip_code" class="main-color">الرمز البريدي</label>
                <v-text-field
                  class=""
                  name="zip_code"
                  v-model="branchObj.zip_code"
                  :rules="[
                    validationRules.required,
                    validationRules.spaces,
                    validationRules.numeric,
                  ]"
                />
              </v-col>
              <v-col cols="2" class="d-none d-sm-block"></v-col>
              <v-col cols="12" sm="5" class="py-0">
                <label for="additionalNumber" class="main-color"
                  >رقم إضافي</label
                >
                <v-text-field
                  class=""
                  name="additionalNumber"
                  v-model="branchObj.additional_number"
                  :rules="[
                    validationRules.required,
                    validationRules.spaces,
                    validationRules.numeric,
                  ]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <label for="salonType" class="main-color d-inline-block mb-4"
                  >النوع</label
                >
                <v-radio-group
                  row
                  v-model="branchObj.gender"
                  :rules="[validationRules.required, validationRules.spaces]"
                >
                  <v-radio
                    v-for="(type, index) in salonTypes"
                    :key="index"
                    :label="type.label"
                    :value="type.name"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <label
                  for="salonType"
                  style="visibility: hidden"
                  class="main-color d-inline-block mb-3"
                  >النوع</label
                >
                <div class="d-flex align-baseline justify-center">
                  <p class="mx-2">مخفى</p>
                  <v-switch
                    v-model="branchObj.active"
                    color="#a53860"
                    inset
                  ></v-switch>
                  <p class="mx-2">مفعل</p>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col cols="12" md="4" class="pr-md-16 mx-auto">
          <Map
            ref="myMap"
            type="addEditAddress"
            @getFormattedAddress="getFormattedAddress"
            v-on:updateLatLng="getLatLng"
            :editCoordinates="editCoordinates"
          ></Map>

          <v-col cols="12" sm="12" style="text-align: center">
            <v-btn
              class=""
              small
              @click="getCurrentPosition"
              style="letter-spacing: 0; background-color: #a53860; color: #fff"
              >إختيار الموقع الحالي تلقائياً</v-btn
            >
            <br />
            <small style="color: #a53860; font-size: 11px"
              >يتطلب السماح باستخدام GPS</small
            >
          </v-col>

          <div class="text-left mt-8">
            <v-btn
              class="main-btn"
              @click.prevent="saveAddEditData"
              :loading="loading"
              >حفظ</v-btn
            >
            <v-btn class="main-btn mx-3" @click.prevent="closeEditMode"
              >الغاء</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>
    <confirmDialog
      v-if="confirmDialog"
      :dialog="confirmDialog"
      :text="dialogText"
      @confirm="deleteBranch"
      @closeConfirmDialog="closeConfirmDialog"
    />
  </div>
</template>

<script>
import { validationMixin } from "@/mixins/validationMixin";
import { mapGetters } from "vuex";
import * as VueGoogleMaps from "vue2-google-maps";
import Map from "@/components/google-maps.vue";
import confirmDialog from "@/components/confirmDialog.vue";
export default {
  name: "branchesShow",
  props: ["branches"],
  components: { Map, confirmDialog },
  mixins: [validationMixin],

  data() {
    return {
      text: "نسخ رابط الفرع",
      confirmDialog: false,
      dialogText: "هل أنت متأكد من حذف الفرع ؟",
      editCoordinates: { lat: null, lng: null },
      valid: false,
      edit: false,
      saveType: "",
      salonTypes: [
        { name: "male", label: "رجال" },
        { name: "female", label: "سيدات" },
        { name: "mix", label: "مختلط" },
      ],
      countries: [],
      cities: [],
      branchObj: {
        id: "",
        name: "",
        country: 1,
        city_id: "",
        district: "",
        street: "",
        zip_code: "",
        additional_number: "",
        gender: "",
        lat: "",
        lng: "",
        can_delete: true,
        active: true,
      },
      loading: false,
      selectedBranchIndex: "",
      selectedBranchId: "",
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
    google: VueGoogleMaps.gmapApi,
  },
  methods: {
    copyText(url) {
      navigator.clipboard.writeText(url);
      this.text = "تم النسخ";
      setTimeout(() => {
        this.text = "نسخ رابط الفرع";
      }, 4000);
    },
    closeEditMode() {
      this.edit = false;
      this.branchObj = {
        id: "",
        name: "",
        country: 1,
        city_id: "",
        district: "",
        street: "",
        zip_code: "",
        additional_number: "",
        gender: "",
        lat: "",
        lng: "",
        can_delete: true,
        active: true,
      };
    },
    getCurrentPosition() {
      this.$refs.myMap.getCurrentPosition();
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    getFormattedAddress(formattedAddress) {
      console.log(formattedAddress);
    },
    openConfirmDialog(branchId, branchIndex) {
      this.confirmDialog = true;
      this.selectedBranchIndex = branchIndex;
      this.selectedBranchId = branchId;
    },
    deleteBranch() {
      this.$http
        .get(
          this.base_url +
            "/salon/branches/" +
            this.selectedBranchId +
            "/delete",
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.$emit("deleteBranch", this.selectedBranchIndex);
            this.confirmDialog = false;
            this.selectedBranchId = "";
            this.selectedBranchIndex = "";
          }
        });
    },
    editBranch(branchIndex) {
      this.selectedBranchIndex = branchIndex;
      this.branchObj = this.branches[branchIndex];
      this.branchObj.country = 1;

      this.edit = true;
      this.saveType = "edit";
    },
    saveAddEditData() {
      if (this.valid) {
        this.loading = true;
        this.$http
          .post(
            this.base_url + "/salon/editUpdateSalonBranches",
            this.branchObj,
            {
              headers: {
                locale: localStorage.locale,
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.loading = false;
            if (response.data.status.error == false) {
              if (this.saveType == "edit") {
                this.$emit(
                  "updateBranch",
                  this.branchObj,
                  this.selectedBranchIndex,
                  this.countries,

                  this.cities
                );
              } else if (this.saveType == "new") {
                this.$emit(
                  "addNewBranch",
                  this.branchObj,
                  this.countries,
                  this.cities
                );
              }
              this.edit = false;
              this.saveType = "";
              this.selectedBranchIndex = "";

              this.branchObj = {
                id: "",
                name: "",
                country: 1,
                city_id: "",
                district: "",
                street: "",
                zip_code: "",
                additional_number: "",
                gender: "",
                lat: "",
                lng: "",
                can_delete: true,
                active: true,
              };
            } else {
              this.validationMsg = response.data.status.message;
            }
          });
      } else {
        this.validate();
      }
    },
    addNewBranch() {
      this.edit = true;
      this.saveType = "new";
    },
    getLatLng(lat, lng) {
      this.branchObj.lat = lat;
      this.branchObj.lng = lng;
      this.editCoordinates.lat = lat;
      this.editCoordinates.lng = lng;
    },
    getCountriesAndCities() {
      this.$http.get(this.base_url + "/salon/cities", {}).then((response) => {
        this.countries = response.data.data.countries;
        this.cities = response.data.data.cities;
      });
    },
  },
  mounted() {
    this.getCountriesAndCities();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";
.main-btn {
  width: 120px !important;
}
.basicInfo {
  .head {
    .main-title {
      color: $second-color;
      font-size: 2rem;
    }
    .line {
      display: inline-block;
      width: 100%;
      height: 1px;
      background: $second-color;
      margin-right: -3px !important;
    }
  }
  .info-content {
  }
}

.unactive-branch {
  p {
    color: #c1c1c1 !important;
  }
}

@media (max-width: 600px) {
  .basicInfo {
    .head {
      .main-title {
        font-size: 1.5rem;
      }
    }
  }
}
</style>
