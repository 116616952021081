<template>
  <div id="workingHours">
    <div class="head">
      <v-row class="align-baseline">
        <v-col cols="12" sm="8" class="pb-0 pb-sm-3">
          <h2 class="main-title mb-0 mb-sm-3">ساعات العمل</h2></v-col
        >
        <v-col cols="12" sm="4" class="text-sm-left">
          <slot></slot>
        </v-col>
      </v-row>
      <p class="d-flex align-center">
        <v-icon color="#a53860" small>mdi-checkbox-blank-circle</v-icon>
        <span class="line"></span>
      </p>
    </div>

    <div class="working-hours mr-md-12">
      <v-row class="show" v-if="edit == false">
        <v-col cols="12" md="9">
          <h3 class="main-color">ساعات العمل</h3>
          <div
            class="item-content my-4"
            v-for="(day, index) in workingHours"
            :key="index"
          >
            <p v-if="day.active == true">
              <span class="d-inline-block day">
                {{ day.week_day }}
              </span>
              <span class="d-inline-block time">
                {{ day.fromVal }} - {{ day.toVal }}
              </span>
            </p>
          </div>

          <div v-if="!checkWorkingHours() && loaded == true">
            <p>لاتوجد ساعات عمل متاحه</p>
          </div>
        </v-col>
        <v-col cols="12" md="3" class="text-center">
          <v-btn class="main-btn" @click.prevent="openEditWorkingHours"
            >تحديث</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="edit my-8" v-else>
        <v-col cols="12" class="pa-0">
          <p class="error--text text-center mb-3" v-if="validationMsg">
            {{ validationMsg }}
          </p>
        </v-col>
        <v-col cols="12" md="9">
          <v-row
            class="d-flex align-center"
            v-for="(day, index) in workingHours"
            :key="index"
          >
            <v-col cols="12" sm="4" md="2" class="py-0">
              <v-checkbox
                class="mt-0 pt-0"
                :label="day.week_day"
                v-model="day.active"
                @change="showResult"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" sm="4" class="py-0 px-0 px-sm-3">
              <fromTimeInput
                :index="index"
                :fromValue="day.from"
                :toValue="day.to"
                @updateFromValue="updateFromValue"
              />

              <p
                class="error--text text-center validation-error"
                v-if="
                  requiredValidationArray.length > 0 &&
                  requiredValidationArray[index].from
                "
              >
                هذا الحقل مطلوب
              </p>
            </v-col>
            <v-col cols="6" sm="4" class="py-0 px-0 px-sm-3">
              <toTimeInput
                :index="index"
                :toValue="day.to"
                :fromValue="day.from"
                @updateToValue="updateToValue"
              />
              <p
                class="error--text text-center validation-error"
                v-if="
                  requiredValidationArray.length > 0 &&
                  requiredValidationArray[index].to
                "
              >
                هذا الحقل مطلوب
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3" class="text-center">
          <v-btn
            class="main-btn"
            @click.prevent="saveWorkingHours"
            :loading="loading"
            >تحديث</v-btn
          >
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import fromTimeInput from "@/components/salon/fromTimeInput.vue";
import toTimeInput from "@/components/salon/toTimeInput.vue";
import { validationMixin } from "@/mixins/validationMixin";
import moment from "moment";

export default {
  name: "workingHours",
  components: { fromTimeInput, toTimeInput },
  mixins: [validationMixin],

  computed: {
    ...mapGetters(["base_url"]),
  },
  data() {
    return {
      loading: false,
      loaded: false,
      workingHours: [],
      selectedDays: [],
      edit: false,
      weekDays: [
        { id: 1, name: "السبت" },
        { id: 2, name: "الاحد" },
        { id: 3, name: "الاثنين" },
        { id: 4, name: "الثلاثاء" },
        { id: 5, name: "الابعاء" },
        { id: 6, name: "الخميس" },
        { id: 7, name: "الجمعة" },
      ],
      timeValidList: [],
      validationMsg: "",
      requiredValidationArray: [],
    };
  },
  methods: {
    showResult() {
      console.log("show");
    },
    openEditWorkingHours() {
      this.edit = true;
    },
    updateFromValue(index, val) {
      this.workingHours[index].from = val;
      this.workingHours[index].fromVal = this.make12HourTime(val);
    },
    updateToValue(index, val) {
      this.workingHours[index].to = val;
      this.workingHours[index].toVal = this.make12HourTime(val);
    },
    make12HourTime(val) {
      let timeVal = val.split(":");
      let hrVal = timeVal[0] >= 12 ? timeVal[0] - 12 : timeVal[0];
      let timeIndicator = timeVal[0] > 12 ? "مساءً" : "صباحًا";

      return hrVal + ":" + timeVal[1] + " " + timeIndicator;
    },

    checkWorkingHours() {
      return this.workingHours.some((day) => day.active == true);
    },

    checkWorkingHoursFromTo() {
      return this.workingHours.some((day) => day.from && day.to);
    },

    getWorkingHours() {
      this.loaded = false;
      this.$http
        .get(this.base_url + "/salon/schedule", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.loaded = true;
          //   console.log(response.data);
          this.workingHours = response.data.data;

          this.workingHours.forEach((item) => {
            item.fromVal = this.make12HourTime(item.from);
            item.toVal = this.make12HourTime(item.to);
          });
        });
    },

    startTimeRule(start, end) {
      var startTime = moment(start, "HH:mm");
      var endTime = moment(end, "HH:mm");
      return startTime >= endTime ? false : true;
      // console.log("error");
    },
    saveWorkingHours() {
      // check from and to is valid
      let errorExist = false;
      this.validationMsg = "";

      for (let index = 0; index < this.workingHours.length; index++) {
        // check field which is empty and active
        this.requiredValidationArray.push({ from: "", to: "" });
        if (this.workingHours[index].active == true) {
          if (!this.workingHours[index].from) {
            this.requiredValidationArray[index].from = true;
          } else {
            this.requiredValidationArray[index].from = false;
          }
          if (!this.workingHours[index].to) {
            this.requiredValidationArray[index].to = true;
          } else {
            this.requiredValidationArray[index].to = false;
          }
        } else {
          this.requiredValidationArray[index].from = false;
          this.requiredValidationArray[index].to = false;
        }

        if (this.requiredValidationArray.some((item) => item.from || item.to)) {
          errorExist = true;
        }

        if (
          !this.startTimeRule(
            this.workingHours[index].from,
            this.workingHours[index].to
          )
        ) {
          errorExist = true;
          break;
        }
      }

      if (
        errorExist == false &&
        this.checkWorkingHours() &&
        this.checkWorkingHoursFromTo()
      ) {
        this.loading = true;
        this.$http
          .post(
            this.base_url + "/salon/schedule",
            { schedule: this.workingHours },
            {
              headers: {
                locale: localStorage.locale,
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              this.loading = false;
              this.edit = false;
            }
          });
      } else {
        if (!this.checkWorkingHoursFromTo()) {
          this.validationMsg = "اختر ساعات العمل اولا";
        }
        if (!this.checkWorkingHours()) {
          this.validationMsg = "اختر يوم عمل واحد على الاقل";
        }
      }
    },
  },
  mounted() {
    this.getWorkingHours();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.basicInfo {
  .head {
    .main-title {
      color: $second-color;
      font-size: 2rem;
    }
    .line {
      display: inline-block;
      width: 100%;
      height: 1px;
      background: $second-color;
      margin-right: -3px !important;
    }
  }
}

.working-hours {
  .item-content {
    .time {
      color: #979797;
    }

    .day {
      width: 120px !important;
    }
  }
}

@media (max-width: 600px) {
  .basicInfo {
    .head {
      .main-title {
        font-size: 1.5rem;
      }
    }
  }
}
</style>
